import PropTypes from 'prop-types';
import * as React from 'react';

import styled from 'styled-components';

import Image from '../../Image';
// import { BigSection } from '../../Marketing/Shared';
import { Typography } from 'components/DesignSystemV2/Typography';
import { SelectWithPrefix } from 'shared/SchedulePage/components/SelectWithPrefix';
import { fromScreen } from 'utils/media-query/responsive.util';
import WhiteButton from '../../MyButton/WhiteButton';
import { CardJob } from '../CardJob';
import TeacherTitle from '../TeacherTitle';

const FilterWrapper = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: 12px;

  max-width: 1080px;
  margin: 0 auto;

  margin-bottom: 24px;
`;

const NoJobWrapper = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  text-align: center;
`;

const Container = styled.div`
  padding: 32px 16px;
  ${fromScreen(458)} {
    padding: 100px 16px 16px;
  }
  ${fromScreen(776)} {
    padding: 100px 32px 32px;
  }
`;

const CardJobList = styled.div`
  display: grid;
  gap: 24px;
  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromScreen(1144)} {
    max-width: 1080px;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ButtonWrp = styled.div`
  text-align: center;
  margin-top: 2em;

  @media screen and (min-width: 1024px) {
    margin-top: 4em;
  }
`;

const artJob = {
  handle: 'VXc2S6RWRMuu5Se7nqMe',
  width: 416,
  height: 175
};

const locationOptions = [
  { label: 'All', value: null },
  { label: 'Hồ Chí Minh', value: 'HCM' },
  { label: 'Hà Nội', value: 'HN' },
  { label: 'Đà Nẵng', value: 'DN' }
];

const teamOptions = [
  { label: 'All', value: null },
  { label: 'Học thuật', value: 'aca' },
  { label: 'Tư vấn và chăm sóc', value: 'sale' },
  { label: 'Marketing', value: 'marketing' }
];

const JobList = props => {
  const { jobList } = props;
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  const updatedJobList = jobList.filter(job => {
    const locationMatch =
      !selectedLocation?.value ||
      job.locationType.includes(selectedLocation.value);
    const teamMatch =
      !selectedTeam?.value || job.teamType === selectedTeam.value;
    return locationMatch && teamMatch;
  });

  return (
    <Container id="job-list">
      <TeacherTitle>
        <div className="artIcon" style={{ width: '100px' }}>
          <Image image={artJob} maxWidth={100} />
        </div>
        See the latest job openings
      </TeacherTitle>

      <FilterWrapper>
        <SelectWithPrefix
          title="Vị trí:"
          defaultValue={null}
          value={selectedTeam}
          options={teamOptions}
          onChange={selectedItem => {
            setSelectedTeam(selectedItem);
          }}
        />
        <SelectWithPrefix
          title="Khu vực:"
          value={selectedLocation}
          defaultValue={null}
          options={locationOptions}
          onChange={selectedItem => {
            setSelectedLocation(selectedItem);
          }}
        />
      </FilterWrapper>

      {updatedJobList?.length === 0 && (
        <NoJobWrapper>
          <img
            src="https://media.dolenglish.vn/directus-upload/106f9300-39bd-4239-8f08-048531bbb0d0.png"
            width="220px"
            height="220px"
            alt="No job"
          />
          <Typography
            variant="regular/14-20"
            tabletVariant="regular/14-20"
            desktopVariant="regular/16-24"
            v3
            color="gray100"
          >
            Không tìm thấy công việc phù hợp
          </Typography>
        </NoJobWrapper>
      )}

      {updatedJobList?.length > 0 && (
        <CardJobList>
          {updatedJobList.map((job, key) => (
            <CardJob
              key={key}
              title={job.title}
              address={job.address}
              salary={job.salaryDisplay}
              slug={`job/${job.slug}`}
            />
          ))}
        </CardJobList>
      )}

      <ButtonWrp style={{ display: 'none' }}>
        <WhiteButton>See more jobs</WhiteButton>
      </ButtonWrp>
    </Container>
  );
};

JobList.propTypes = {
  jobList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default JobList;
