import React from 'react';

import styled from 'styled-components/macro';

import getAssetLink from 'utils/getAssetLink';

import Heading from './heading';
import Section from './section';

const DedicatedTraining = () => {
  return (
    <Section>
      <Grid>
        <Heading
          title="Đào tạo tận tâm"
          description="Được đào tạo 1-1 để phát triển khả năng thiết kế bài giảng, thiết kế tài liệu và thiết kế cách truyền tải nội dung, cách tư vấn và chăm sóc học viên chu đáo từ mỗi vị trí."
        />

        <img
          src={getAssetLink({
            item: { handle: 'sndHgpqeS16Ibel52THR' },
            width: 523
          })}
          alt="Đào tạo tận tâm"
        />
      </Grid>
    </Section>
  );
};

DedicatedTraining.propTypes = {};

DedicatedTraining.defaultProps = {};

export default React.memo(DedicatedTraining);

const Grid = styled.div`
  display: grid;
  justify-content: center;
  padding: 4rem 0;

  @media screen and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1141px) {
    padding: 0;
    height: 690px;
    align-items: center;
  }
`;
