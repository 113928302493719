import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@dolstaff/shared/es/Button';
import IcArrowLeft from '@dolstaff/shared/es/icons/OutlineArrowIcArrowLeft';
import IcArrowRight from '@dolstaff/shared/es/icons/OutlineArrowIcArrowRight';
import Left from '@dolstaff/shared/es/icons/OutlineArrowIcLeftArrow';
import Right from '@dolstaff/shared/es/icons/OutlineArrowIcRightArrow';
import styled from 'styled-components/macro';

function useSlider(total) {
  const [currentIdx, setIdx] = useState(0);

  const next = React.useCallback(() => {
    if (currentIdx < total - 1) setIdx(currentIdx + 1);
  }, [total, currentIdx]);

  const prev = React.useCallback(() => {
    if (currentIdx > 0) setIdx(currentIdx - 1);
  }, [currentIdx]);

  return { currentIdx, next, prev, setIdx };
}

const Slider = ({ children, onNext, onPrev }) => {
  return (
    <Grid>
      <Button type="link" round onClick={onPrev}>
        <Left size={32} />
      </Button>
      <Slide>{children}</Slide>
      <Button type="link" round onClick={onNext}>
        <Right size={32} />
      </Button>
    </Grid>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

Slider.defaultProps = {
  onNext: () => {},
  onPrev: () => {}
};

export const FlatSlider = ({ children, onNext, onPrev }) => {
  return (
    <div className="flat-slider-container">
      <div className="slide">{children}</div>
      <Button className="btn-left" type="link" round onClick={onPrev}>
        <IcArrowLeft size={32} />
      </Button>
      <Button className="btn-right" type="link" round onClick={onNext}>
        <IcArrowRight size={32} />
      </Button>
    </div>
  );
};

FlatSlider.propTypes = {
  children: PropTypes.node.isRequired,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

FlatSlider.defaultProps = {
  onNext: () => {},
  onPrev: () => {}
};

const Slide = styled.div`
  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(45, 55, 66, 0.1);
  padding: 32px 40px;
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas: 'content content' 'left right';
  grid-template-rows: 1fr 60px;
  grid-gap: 20px;
  max-width: 100%;
  margin: 4em 0;

  button:first-child {
    grid-area: left;
  }

  ${Slide} {
    grid-area: content;
  }

  button:last-child {
    grid-area: right;
  }

  button {
    background: transparent;
    justify-self: stretch;
    align-self: stretch;
    border-radius: 8px;
    margin: 0;
    width: auto;
    height: auto;
  }

  @media screen and (min-width: 769px) {
    grid-template-columns: 82px 1fr 82px;
    grid-template-rows: 1fr;
    grid-template-areas: 'left content right';

    button:first-child {
      justify-self: start;
      grid-area: left;
    }

    button:last-child {
      justify-self: end;
      grid-area: right;
    }

    button {
      background-color: transparent;
    }
  }
`;

export default React.memo(Slider);
export { useSlider, Grid, Slide };
