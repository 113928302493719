import React from 'react';

import styled from 'styled-components/macro';

import getAssetLink from 'utils/getAssetLink';

import BenefitList from './benefit-list';
import Section from './section';

const Success = () => {
  return (
    <Section>
      <Grid>
        <BenefitList
          title="Môi trường cởi mở, chăm sóc và
            đề cao sự khác biệt"
          benefits={[
            'Môi trường làm việc cởi mở, thân thiện, linh hoạt, luôn ghi nhận đóng góp ý kiến và tạo điều kiện cho giáo viên đóng góp vào việc xây, cải tiến chương trình offline, xây dựng trải nghiệm luyện tập online.',
            'Tất cả mọi thành viên trong công ty đều rất thân thiện và sẵn sàng giúp đỡ. Trainer, Director luôn chia sẽ kiến thức nhiệt tình; giám đốc trung tâm linh hoạt giờ dạy theo tình trạng cá nhân.  '
          ]}
        ></BenefitList>

        <img
          src={getAssetLink({
            item: { handle: 'x8q2I4ByRg2thosZocAb' },
            width: 465
          })}
          alt="success"
        />
      </Grid>
    </Section>
  );
};

Success.propTypes = {};

Success.defaultProps = {};

export default React.memo(Success);

const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  transition: all 0.2s;
  justify-content: center;
  padding: 4rem 0;

  @media screen and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
    padding-top: 50px;
    align-items: center;
  }

  @media screen and (min-width: 1141px) {
    height: 713px;
    padding-top: 0;
  }
`;
