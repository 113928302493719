import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import Button from '@dolstaff/shared/es/Button';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components/macro';

import Slider, { Grid as SliderGrid, useSlider } from 'components/basic-slider';
import Section from 'shared/LandingTeacher/sections/section';
import Modal from 'shared/VideoModal';
import getAssetLink from 'utils/getAssetLink';

import Review from './review';

const TeacherReviews = () => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        reviews: teacherReviews {
          id
          name
          img {
            handle
          }
          content {
            html
          }
        }
      }
    }
  `);

  const { reviews } = data.gcms;
  const { currentIdx, next, prev, setIdx } = useSlider(reviews.length);
  const contentRef = useRef();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const { innerText } = contentRef.current;
      const words = innerText.split(' ');
      // cut words if more than 50
      if (words.length >= 50 && innerText.indexOf('Read more') === -1) {
        contentRef.current.innerText = words.slice(0, 50).join(' ');
        const span = document.createElement('span');
        // Append read more button after 50 words
        // Look like this: 50 words ... read more
        ReactDOM.render(
          <>
            {`... `}
            <Button
              className="read-more"
              type="link"
              round
              onClick={() => {
                if (window.innerWidth > 768) {
                  setShowModal(true);
                } else {
                  contentRef.current.innerHTML = '';
                  contentRef.current.innerHTML =
                    reviews[currentIdx].content.html;
                }
              }}
            >
              Read more
            </Button>
          </>,
          span
        );
        contentRef.current.append(span);
      }

      contentRef.current.style.visibility = 'visible';
    }
  }, [currentIdx, contentRef, reviews]);

  if (!reviews || (reviews && !reviews.length)) return null;

  const { id, img, content, name } = reviews[currentIdx];

  return (
    <Section style={{ backgroundColor: '#F4F5F7', padding: '4em 0' }}>
      <Heading>Review của giáo viên</Heading>
      <Slider onPrev={prev} onNext={next}>
        <Review
          key={id}
          id={id}
          handle={img.handle}
          ref={contentRef}
          content={content.html}
          name={name}
        />
      </Slider>
      <Grid>
        <span />
        <PhotoList>
          {reviews.map(({ id, img: { handle } }, idx) => (
            <TeacherImage
              onClick={() => {
                contentRef.current.style.visibility = 'hidden';
                setIdx(idx);
              }}
              active={idx === currentIdx}
              key={id}
              src={getAssetLink({ item: { handle }, height: 56 })}
            />
          ))}
        </PhotoList>
        <span />
      </Grid>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <Slider onPrev={prev} onNext={next}>
          <Review
            full
            id={id}
            handle={img.handle}
            content={content.html}
            name={name}
          />
        </Slider>
      </Modal>
    </Section>
  );
};

TeacherReviews.propTypes = {};

TeacherReviews.defaultProps = {
  previews: []
};

export default React.memo(TeacherReviews);

const Grid = styled(SliderGrid)`
  display: grid;
  align-items: center;
  grid-template-areas: 'content content' 'left right';
  grid-template-rows: 1fr 60px;
  grid-gap: 20px;
  max-width: 100%;
  margin: 4em 0;
`;

const Heading = styled.h2`
  font-size: 40px;
  color: #242c34;
  text-align: center;
  margin: 0;
`;

const PhotoList = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;

  @media screen and (min-width: 769px) {
    flex-wrap: wrap;
  }
`;

const TeacherImage = styled.img`
  filter: ${p => (p.active ? 'grayscale(0)' : 'grayscale(1)')};
  margin-right: 24px;
  cursor: pointer;
  transition: all 0.3s;

  @media screen and (min-width: 769px) {
    margin-bottom: 48px;

    &:not(:last-child) {
      margin-right: 48px;
    }
  }
`;
