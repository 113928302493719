import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Main = styled.div`
  color: #242c34;
  text-align: center;
  margin-bottom: 1em;
  font-weight: 800;
  line-height: 1.2;
  font-size: 24px;

  @media screen and (min-width: 769px) {
    font-size: 32px;
  }

  &.dark {
    color: white;
  }

  .artIcon {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1024px) {
    font-size: 48px;
  }
`;

const TeacherTitle = ({ children, ...props }) => {
  return <Main {...props}>{children}</Main>;
};

TeacherTitle.propTypes = {
  children: PropTypes.node
};

export default TeacherTitle;
