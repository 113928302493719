import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import IconRemove from '@dolstaff/shared/es/icons/Close';
import cl from 'classnames';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  pointer-events: auto;
  overflow: hidden;
  visibility: hidden;
  z-index: 200;

  &.active {
    visibility: visible;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;
  transform: translate(-50%, -50%);
`;

const CloseButton = styled.button`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 1em;
  right: 1em;
  color: white;
  outline: none;
  cursor: pointer;
  z-index: 1;
`;

class VideoModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.modelRef = React.createRef();
  }

  componentDidUpdate() {
    document.addEventListener('keydown', evt => {
      if (evt.keyCode === 27) {
        this.onClose();
      }
    });
  }

  onClose = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  render() {
    const { isOpen, children } = this.props;

    if (!isOpen) {
      return '';
    }

    return ReactDOM.createPortal(
      <Backdrop data-modal className={cl({ active: isOpen })}>
        <CloseButton onClick={() => this.onClose()}>
          <IconRemove size={16} />
          <span>esc</span>
        </CloseButton>
        <Wrapper ref={this.modelRef}>{children}</Wrapper>
      </Backdrop>,
      document.body
    );
  }
}

export default VideoModal;
