import React from 'react';

import styled from 'styled-components/macro';

import getAssetLink from 'utils/getAssetLink';

import BenefitList, { Title } from './benefit-list';
import Section from './section';

const Salary = () => {
  return (
    <Section style={{ backgroundColor: '#EFEEFB' }}>
      <Grid>
        <img
          src={getAssetLink({
            item: { handle: 'hkbsvmaSKS5aeriVvKBw' },
            width: 466
          })}
          alt="salary"
        />

        <Description>
          <BenefitList
            title="Lương cao và thưởng rộng rãi, minh bạch"
            benefits={[
              'Lương cao top thị trường, thưởng rộng rãi dựa trên đánh giá performance từng khoá rõ ràng và hợp lý.',
              'Các phúc lợi khác.'
            ]}
          />
        </Description>
      </Grid>
    </Section>
  );
};

Salary.propTypes = {};

Salary.defaultProps = {};

export default React.memo(Salary);
const Description = styled.div`
  grid-area: description;
`;
const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  transition: all 0.2s;
  justify-content: center;
  padding: 4rem 0;
  img {
    grid-area: image;
  }

  @media screen and (max-width: 768px) {
    grid-template-areas:
      'description'
      'image';
  }

  @media screen and (min-width: 769px) {
    grid-template-areas: 'image description';
    grid-gap: 80px;
    padding-top: 50px;
    align-items: center;

    ${Title} {
      font-size: 32px;
      color: #150e63;
    }
  }

  @media screen and (min-width: 1141px) {
    height: 713px;
    padding-top: 0;
  }
`;
