import React, { useState } from 'react';

import styled from 'styled-components/macro';

import VideoModal from 'shared/VideoModal';
import getAssetLink from 'utils/getAssetLink';
import useMediaQuery from 'utils/react-responsive';

import Heading, { Content as HeadingContent } from './heading';
import Section from './section';

const Methodology = () => {
  const { isTabletOrMobile } = useMediaQuery();
  const [showModal, setShowModal] = useState(false);
  return (
    <Section style={{ backgroundColor: '#F4F5F7' }}>
      <Grid>
        <Heading
          style={{ maxWidth: 'unset' }}
          title="Phương pháp tư duy độc quyền"
          description="Trở thành giáo viên của DOL, bạn sẽ được training phương pháp Linearthinking - một phương pháp học tiếng Anh tư duy hơn, hiệu quả hơn được DOL sáng tạo."
        />
        {isTabletOrMobile ? (
          <ImageWrp onClick={() => setShowModal(true)}>
            <img
              style={{ transform: `scale(1.2)` }}
              src={getAssetLink({
                item: { handle: 'QvkLbdhySBOkukRBPBAm' },
                width: 600
              })}
              alt="success"
            />
            <RippleImage />
          </ImageWrp>
        ) : (
          <ImageWrp onClick={() => setShowModal(true)}>
            <img
              src={getAssetLink({
                item: { handle: 'cq3kA0hFQCyFrF7hAZ8v' },
                width: 1000
              })}
              alt="success"
            />
            <RippleImage />
          </ImageWrp>
        )}
      </Grid>
      <VideoModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <video
          className="videoPlayer"
          controls
          preload="meta"
          autoPlay
          id="video-auto-play-modal"
          src={getAssetLink({ item: { handle: 'JsAA4xjQFOn1JYGnwz3g' } })}
          type="video/mp4"
          style={{ width: '100%' }}
        >
          <track kind="captions" />
        </video>
      </VideoModal>
    </Section>
  );
};

Methodology.propTypes = {};

Methodology.defaultProps = {};

export default React.memo(Methodology);
const ImageWrp = styled.div`
  position: relative;
`;
const RippleImage = styled.div`
  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0.2rem rgba(69, 78, 196, 0.5);
    }
    100% {
      box-shadow: 0 0 0 1.4rem rgba(69, 78, 196, 0);
    }
  }

  @media screen and (min-width: 769px) {
    position: absolute;
    background-color: transparent;
    bottom: calc(16% + 1px);
    width: calc(8% + 2px);
    height: calc(11% - 4px);
    border-radius: 8px;
    z-index: 1;
    left: 42%;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    background-color: transparent;
    bottom: calc(11% + 1px);
    width: calc(12% + 1px);
    height: calc(12% - 3px);
    border-radius: 8px;
    z-index: 1;
    left: 68%;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
  }
`;
const Grid = styled.div`
  display: grid;
  justify-content: center;
  text-align: center;
  padding: 4em 0;

  img {
    cursor: pointer;
  }

  h3 {
    margin: 1rem 0;
  }

  @media screen and (max-width: 768px) {
    overflow: hidden;
    ${HeadingContent} {
      p {
        max-width: 600px;
      }
    }
  }
  @media screen and (min-width: 769px) {
    ${HeadingContent} {
      p {
        max-width: 1000px;
      }
    }
  }
`;
