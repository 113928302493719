import React from 'react';

import styled from 'styled-components/macro';

import getAssetLink from 'utils/getAssetLink';

import BenefitList from './benefit-list';
import Section from './section';

const Success = () => {
  return (
    <Section
      style={{
        background: `linear-gradient(0deg, #fff7eb, #fff7eb),
    radial-gradient(
      84.71% 84.71% at 87.81% 89.36%,
      #feeae7 41.79%,
      rgba(212, 233, 255, 0.15) 100%
    )`
      }}
    >
      <Grid>
        <img
          src={getAssetLink({
            item: { handle: 'zItvpDaAQ3KU7IIK6AYZ' },
            width: 465
          })}
          alt="review"
        />

        <Description>
          <BenefitList
            title="Tạo mọi điều kiện hoàn thành công việc và phát triển"
            benefits={[
              'Tất cả mọi công việc đã có file hướng dẫn, và có trainer theo kèm cặp để đảm bảo mọi buổi dạy đều thật hoàn hảo.',
              'Có đội ngũ chấm bài viết nói, chấm bài đầu, giữa, cuối khoá hỗ trợ.',
              'Lộ trình thăng tiến lên các vị trí Trainer, Director cho những bạn giỏi và giàu kinh nghiệm muốn chuyển qua làm giờ hành chính.',
              'Có nhiều chương trình sẽ phát triển xong trong vài năm tới, có nhiều đất cho các bạn đóng góp. '
            ]}
          />
        </Description>
      </Grid>
    </Section>
  );
};

Success.propTypes = {};

Success.defaultProps = {};

export default React.memo(Success);
const Description = styled.div`
  grid-area: description;
`;
const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  transition: all 0.2s;
  justify-content: center;
  padding: 4rem 0;
  img {
    grid-area: image;
  }

  @media screen and (max-width: 768px) {
    grid-template-areas:
      'description'
      'image';
  }

  @media screen and (min-width: 769px) {
    grid-template-areas: 'image description';
    grid-gap: 80px;
    padding-top: 50px;
    align-items: center;
  }

  @media screen and (min-width: 1141px) {
    height: 713px;
    padding-top: 0;
  }
`;
