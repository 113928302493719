import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';

const Heading = ({ title, description, ...props }) => {
  return (
    <Content {...props}>
      <h3>{title}</h3>
      <p>{description}</p>
    </Content>
  );
};

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

Heading.defaultProps = {
  description: ''
};

export default React.memo(Heading);

export const Content = styled.div`
  color: #2d3742;
  max-width: 475px;

  h3 {
    font-size: 32px;
    font-weight: bold;
  }
`;
