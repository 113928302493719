import * as React from 'react';

import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';

import { ButtonStyles } from 'components/ButtonStyles/ButtonStyles';

const Container = styled.div`
  min-height: 276px;
  display: flex;
  flex-direction: column;

  padding: 24px;
  border: 1px solid ${colorsV2.gray20};
  border-radius: 8px;
  .info {
    margin-bottom: 28px;
  }
  .action {
    margin-top: auto;
  }
`;

const Status = styled.div`
  width: fit-content;
  border-radius: 30px;
  padding: 2px 8px;
  background-color: ${colorsV2.black5};

  &.available {
    background-color: ${colorsV2.blue5};
    color: ${colorsV2.blue100};
  }
`;

const Content = styled.div``;

const ApplyNow = styled.div`
  padding: 10px 16px;
`;

export const CardJob = ({ title, address, salary, slug }) => {
  return (
    <Container>
      <div className="info">
        <Status className="available">
          <ResponsiveTypography.Paragraph variant="semi-bold/12-20">
            Đang tuyển dụng
          </ResponsiveTypography.Paragraph>
        </Status>
        <Content>
          <ResponsiveTypography.Paragraph
            variant="bold/20-28"
            color="black100"
            mt="8px"
            mb="16px"
          >
            {title}
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph
            variant="medium/16-24"
            color="black80"
            mb="8px"
          >
            {address}
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph
            variant="medium/16-24"
            color="black80"
          >
            {salary}
          </ResponsiveTypography.Paragraph>
        </Content>
      </div>

      <div className="action">
        <a href={slug}>
          <ButtonStyles
            buttonStyle="filled"
            content={
              <ApplyNow>
                <ResponsiveTypography.Paragraph
                  variant="semi-bold/14-20"
                  color="white100"
                >
                  Ứng tuyển ngay
                </ResponsiveTypography.Paragraph>
              </ApplyNow>
            }
            color="primary"
            colorStyle="bold"
          />
        </a>
      </div>
    </Container>
  );
};
