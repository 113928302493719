import React from 'react';

import styled from 'styled-components/macro';

import getAssetLink from 'utils/getAssetLink';

import BenefitList from './benefit-list';
import Section from './section';

const Benefit = () => {
  return (
    <Section>
      <Grid>
        <TitleContainer>
          <Title
            style={{
              textTransform: 'uppercase',
              fontSize: 18,
              fontWeight: 400
            }}
          >
            tham gia với dol
          </Title>
          <Title>Bạn sẽ nhận được</Title>
        </TitleContainer>

        <img
          src={getAssetLink({
            item: { handle: 'AMJ7hv8IRE2BK2Msa94R' },
            width: 465
          })}
          alt="benefit"
        />

        <BenefitContent>
          <BenefitList
            title="Cơ hội sáng tạo với DOL English"
            benefits={[
              'Sáng tạo phương pháp học Tiếng Anh tư duy hơn.',
              'Sáng tạo cách dẫn dắt, truyền đạt nội dung hay hơn.',
              'Sáng tạo phần luyện tập online, mang lại trải nghiệm tốt nhất cho học viên.',
              'Sáng tạo các cách thức mới mang lại nhiều giá trị hơn cho học viên.'
            ]}
          >
            <li style={{ marginTop: '2rem' }}>
              Làm việc cùng DOL nghĩa là cho bản thân cơ hội không ngừng sáng
              tạo và phát triển!
            </li>
          </BenefitList>
        </BenefitContent>
      </Grid>
    </Section>
  );
};

Benefit.propTypes = {};

Benefit.defaultProps = {};

export default React.memo(Benefit);

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #242c34;
  margin: 0.5em 0;
  text-align: center;

  @media screen and (min-width: 769px) {
    font-size: 40px;
  }
`;

const BenefitContent = styled.div`
  justify-self: end;
`;

const TitleContainer = styled.div`
  margin: 2rem;
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  padding-bottom: 72px;

  @media screen and (min-width: 769px) {
    grid-template-areas: 'header header ' 'img content';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content 1fr;
    padding: 36px 0 72px;
    grid-column-gap: 40px;

    ${TitleContainer} {
      grid-area: header;
    }

    img {
      grid-area: img;
    }

    ${BenefitContent} {
      grid-area: content;
    }
  }
`;
