import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import StudentReviews from 'shared/StudentCourseLanding/StudentReviews';

import { Container } from '../../Blocks';
import { BigSection, NormalText } from '../../Marketing/Shared';
import TeacherTitle from '../TeacherTitle';

const Main = styled.div`
  .header {
    margin-bottom: 2em;
  }

  .controlWrp {
    text-align: center;
    margin-top: 2em;
  }

  @media screen and (min-width: 1024px) {
    .header {
      margin-bottom: 6em;
    }
  }
`;

const ITEM_PER_PAGE = 6;

class FacebookReview extends React.PureComponent {
  constructor(props) {
    super(props);

    const { facebookReviewList } = props;

    this.state = {
      numberOfFacebookReview: ITEM_PER_PAGE,
      facebookReviewList
    };
  }

  setNumberOfFacebookReview = numberOfFacebookReview => {
    this.setState({ numberOfFacebookReview });
  };

  render() {
    return (
      <BigSection selfish style={{ backgroundColor: '#FBFBFB' }}>
        <Container>
          <Main>
            <div className="header">
              <TeacherTitle style={{ textAlign: 'center', fontSize: 32 }}>
                Rất, rất nhiều tình cảm của học viên.
              </TeacherTitle>
              <NormalText
                style={{
                  maxWidth: '803px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  textAlign: 'center',
                  fontSize: 16,
                  fontWeight: 'normal'
                }}
              >
                Hơn tất cả, bạn sẽ nhận được những review 8 cây số, tình cảm bao
                la của học viên, đây là điểm đáng tự hào nhất, tạo nên giá trị
                và động lực cho một giáo viên. Ở DOL, nếu có tâm, tình cảm học
                viên là thứ bạn không bao giờ thiếu.
              </NormalText>
            </div>
            <StudentReviews
              bubble={[]}
              classType="offline"
              columns={{ default: 3, 1365: 2, 768: 1 }}
            />
          </Main>
        </Container>
      </BigSection>
    );
  }
}

FacebookReview.propTypes = {
  facebookReviewList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default FacebookReview;
