import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'shared/Blocks';

const Section = ({ children, ...props }) => {
  return (
    <section {...props}>
      <Container>{children}</Container>
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired
};

Section.defaultProps = {};

export default React.memo(Section);
