import React from 'react';
import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components/macro';

import getAssetLink from 'utils/getAssetLink';

const ReviewContent = React.forwardRef(
  ({ id, full, handle, content, name }, ref) => {
    return (
      <Slide key={id} full={full}>
        <img
          src={getAssetLink({
            item: { handle },
            height: 56
          })}
        />

        <div ref={ref} dangerouslySetInnerHTML={{ __html: content }} />

        <Footer>
          <p>{name}</p>
          <span>ielts teacher</span>
        </Footer>
      </Slide>
    );
  }
);

ReviewContent.propTypes = {
  id: PropTypes.string,
  handle: PropTypes.string,
  content: PropTypes.string,
  name: PropTypes.string,
  full: PropTypes.bool
};

export default React.memo(ReviewContent);

const fade = keyframes`

  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

`;

const Slide = styled.div`
  display: grid;
  grid-row-gap: 24px;
  font-size: 18px;
  line-height: 32px;
  grid-template-rows: 56px max-content max-content;
  background-color: #fff;
  overflow: hidden;

  animation: ${fade} 0.3s;

  @media screen and (min-width: 769px) {
    grid-template-rows: 60px ${p => (p.full ? '1fr' : 'minmax(100px, 150px)')} max-content;
  }
`;

const Footer = styled.div`
  color: #2d3742;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  p {
    margin-bottom: 0;
    line-height: 1;
  }

  span {
    color: #7a869a;
  }
`;
