import React from 'react';
import PropTypes from 'prop-types';

import OutlineInterfaceIcDCheck from '@dolstaff/shared/es/icons/OutlineInterfaceIcDCheck';
import styled from 'styled-components/macro';

const BenefitList = ({ title, benefits, children }) => {
  return (
    <BenefitItem>
      <Title>{title}</Title>
      <List>
        {benefits.map(benefit => (
          <li key={benefit}>
            <Bullet>
              <OutlineInterfaceIcDCheck size={17} />
            </Bullet>
            {benefit}
          </li>
        ))}
        {children}
      </List>
    </BenefitItem>
  );
};

BenefitList.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  title: PropTypes.string.isRequired
};

BenefitList.defaultProps = {
  children: null
};

export default React.memo(BenefitList);

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #2d3742;
  margin: 0.5em 0;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 12px;
    align-items: center;
    font-size: 18px;
    color: #000;
  }

  @media screen and (min-width: 769px) {
    font-size: 1rem;
  }
`;

const Bullet = styled.div`
  display: inline-flex;
  width: 24px;
  height: 24px;
  background-color: #00875a;
  color: white;
  text-align: center;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
`;

const BenefitItem = styled.div`
  max-width: 430px;

  @media screen and (min-width: 769px) {
    max-width: unset;
  }
`;
