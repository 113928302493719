import React from 'react';
import PropTypes from 'prop-types';

import Benefit from 'shared/LandingTeacher/sections/benefit';

import DedicatedTraining from './sections/dedicated-training';
import Environment from './sections/environment';
import Methodology from './sections/methodology';
import Review from './sections/reviews';
import Salary from './sections/salary';
import Success from './sections/success';
import TeacherReview from './teacher-reviews';

const LandingTeacher = ({ facebookReviewList }) => {
  return (
    <div>
      <Benefit />
      <Review facebookReviewList={facebookReviewList} />
      <Methodology />
      <DedicatedTraining />
      <Success />
      <Environment />
      <Salary />
      <TeacherReview />
    </div>
  );
};

LandingTeacher.propTypes = {
  facebookReviewList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default React.memo(LandingTeacher);
