import * as React from 'react';
import merge from 'lodash/merge';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { Typography } from '../../../components/DesignSystemV2/Typography';
import { colorsV2 } from '../../../style/colors-v2';
import { toScreen } from '../../../utils/media-query/responsive.util';

export const SelectWithPrefixWrapper = styled.div`
  display: flex;
  width: max-content;
  border-radius: 8px;
  padding: 4px 8px;
  background-color: ${colorsV2.gray10};
  align-items: center;
`;
export const SelectWithPrefix = ({ title, ...props }) => {
  const config = merge(
    {},
    {
      isSearchable: false,
      placeholder: 'All',
      components: {
        IndicatorSeparator: function () {
          return null;
        }
      },
      styles: {
        menuList: provided => ({
          ...provided,
          maxHeight: 400
        }),
        menu: provided => ({
          ...provided,
          width: 'max-content'
        }),
        container: provided => ({
          ...provided,
          cursor: 'pointer'
        }),
        indicatorsContainer: provided => {
          return {
            ...provided,
            '> div': {
              padding: 0,
              '> svg': {
                width: 24,
                height: 24
              }
            }
          };
        },
        placeholder: provided => ({
          ...provided,
          color: colorsV2.black100,
          fontWeight: 500,
          fontSize: 14,
          position: null,
          top: null,
          transform: null
        }),
        singleValue: provided => ({
          ...provided,
          color: colorsV2.black100,
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '20px',
          maxWidth: null,
          position: null,
          top: null,
          transform: null
          /* margin-left: 2px; */
          /* margin-right: 2px; */
          /* overflow: hidden; */
          /* position: absolute; */
          /* top: 50%; */
          /* -webkit-transform: translateY(-50%); */
          /* transform: translateY(-50%); */
        }),
        valueContainer: provided => ({
          ...provided,
          [toScreen(767)]: {
            paddingLeft: 0,
            paddingRight: 10
          }
        }),
        control: (provided, state) => {
          return {
            ...provided,
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderStyle: null,
            borderWidth: null,
            borderColor: null,
            borderRadius: null,
            lineHeight: '20px',
            minHeight: 20,
            width: state.selectProps.width,
            '&:hover': {
              borderColor: 'none',
              boxShadow: 'none'
            }
          };
        }
      }
    },
    props
  );

  return (
    <SelectWithPrefixWrapper>
      <Typography variant="medium/14" color={colorsV2.gray100}>
        {title}
      </Typography>
      <ReactSelect {...config} />
    </SelectWithPrefixWrapper>
  );
};
